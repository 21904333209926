<template>
  <div class="h-container">
    <div class="border-y border-border pb-12">
      <div
        ref="contentModuleContainer"
        class="relative grid overflow-hidden transition-all duration-1000"
        :class="{ '!max-h-[800px]': !isOpen }"
        :style="{ 'max-height': maxHeight }"
      >
        <div class="text col-span-8 mx-auto max-w-[800px] pb-24 pt-10">
          <slot />
        </div>

        <div class="absolute bottom-0 left-0 flex w-full items-end justify-center bg-gradient-to-t from-background from-50% transition-all" :class="isOpen ? 'h-20' : 'h-40'">
          <Button v-if="t('w.global.read_more')" icon-name="arrow-right" class="btn-black btn-rounded mb-2 md:px-5 md:py-3 md:text-button-xl" @click="isOpen = !isOpen">
            {{ isOpen ? t('w.global.read_less') : t('w.global.read_more') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from '~/components/button/Button.vue'

const { t } = useTranslate()
const route = useRoute()

const contentModuleContainer = ref<HTMLDivElement | null>(null)
const isOpen = ref(true)
const maxHeight = ref('max-content')

const updateMaxHeight = () => {
  const contentEl = contentModuleContainer.value
  if (contentEl) {
    maxHeight.value = `${contentEl.scrollHeight}px`
  }
}

const linkClicked = () => {
  if (!isOpen.value) {
    maxHeight.value = 'max-content'
    isOpen.value = true
    nextTick(() => {
      updateMaxHeight()
    })
  }
}

provide('dynamic-node', {
  linkClicked,
})

onMounted(() => {
  if (!route.hash || route.hash === '#') {
    isOpen.value = false
  }

  updateMaxHeight()
})
</script>
